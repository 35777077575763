.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Tab Container */
.Tabs {
  position: relative;
  width: 80%;
  min-height: 75vh;
  background: rgba(21, 23, 32, 1);
  margin: 1rem auto;
  padding: 2rem 1rem;
  color: #000000;
  font: 400 1.5rem/1.5rem "Roboto", sans-serif;

  @media (max-width: 769px) {
    padding: 2rem 0;
  }

  /* upper left corner triangle */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 2rem solid transparent;
    border-left: 2rem solid #5d9603;
  }

  /* lower right corner triangle */
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 2rem solid transparent;
    border-right: 2rem solid #5d9603;
  }
}

/* Tab Navigation */
ul.nav-tabs {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgba(21, 23, 32, 1);
  border-radius: 5px;
  background-color: rgba(21, 23, 32, 1);
  padding-left: 0px;

  @media (max-width: 768px) {
    width: 95%;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }
}

ul.nav-tabs li {
  width: calc(100% / 3.1);
  padding: 1rem 0.5rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Rajdhani', sans-serif;
  position: relative;
  @media (max-width: 768px) {
    width: 80%;
  }
}

ul.nav-tabs li::before, ul.nav-tabs li::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #000000;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

ul.nav-tabs li::before {
  top: 5px;
  transform-origin: center right;
}

ul.nav-tabs li:hover::before {
  transform-origin: center left;
  transform: scaleX(1);
}

ul.nav-tabs li::after {
  bottom: 5px;
  transform-origin: center left;
}

ul.nav-tabs li:hover::after {
  transform-origin: center right;
  transform: scaleX(1);
}

ul.nav-tabs li:hover {
  background: #a3ff12;
  border-radius: 5px;
  color: #000000;
}

ul.nav-tabs li.active {
  background: #a3ff12;
  border-radius: 5px;
  color: #000000;
}

/* Tab Content Styles */
.TabContent {
  font-size: 2rem;
  text-align: center;
}

.ledger-tab-content {
  color: aliceblue;
}

.Deposit__no-wallet {
  color: aliceblue;
  padding: 10px;
  margin: 100px 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.create-wallet-button {
  border: none;
  border-radius: 0;
  padding: 20px 20px;
  margin-top: 20px;
  background: linear-gradient(90deg, #95b5ec, #fbc2eb);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.5s;
}

.create-wallet-button.loading {
  background: #fbc2eb;
  pointer-events: none;
  /* Disable button while loading */
}

.create-wallet-button:hover {
  background: linear-gradient(90deg, #fbc2eb, #a6c1ee);
}

.deposit-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #12121A;
  min-height: 70vh;

}

.amount-options {

  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1rem;

  @media (max-width: 1200px) {
    display: block;
  }

}

.custom-amount {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.amount-btn,
.deposit-btn,
.cancel-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: background-color 0.3s ease;
  margin-bottom: 6vh;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 3vh;
  }
}

.amount-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Rajdhani', sans-serif;
  color: #222430;
  padding: 20px 30px;
  background-color: #a3ff12;
  border: 2px solid #88DE00;
  border-radius: 5px;
  box-shadow: 0 0 10px #87d607;
  text-shadow: 1px 1px 2px #88DE00;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
  @media (max-width: 992px) {
   height: 65px;
  }
}

.amount-btn:before {
  content: '$';
  margin-right: 5px;
}

.amount-btn:hover {
  color: #ffffff;
  background-color: #88DE00;
  border-color: #88DE00;
  text-shadow: none;
  transform: translateY(-5px);
}

.deposit-btn {
  background-color: #28a745;
  color: #fff;
  padding: 1.5rem 2rem;
}

.deposit-btn:hover, .withdraw-btn:hover {
  background-color: #218838;
}

.deposit-btn:disabled, .withdraw-btn:disabled {
  background-color: #6c757d;
  cursor: default;
}

.cancel-btn {
  background-color: #dc3545;
  color: #fff;
  padding: 1.5rem 2rem;
}

.cancel-btn:hover {
  background-color: #c82333;
}

.custom-deposit-input,
.custom-withdraw-input,
.custom-transfer-input {
  padding: 0.5rem;
  border: 2px solid #a3ff12;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: 3rem;
  width: 25rem;

  @media (max-width: 768px) {
    width: 15rem;
    font-size: 1rem;
  }
}

.balance-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
  margin: 20px 0 40px;
  border-radius: 8px;
  background: rgba(21, 23, 32, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.available-balance-container{
  padding: 20px;
  border-radius: 8px;
  background: rgb(14, 17, 29);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
}

.balance-title {
  color: #a3ff12;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    font-size: .4em;
  }
}

.in-competition-balance-amount
 {
  color: #ffc107;
  font-size: .8em;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: .6em;
  }
}

.available-balance-amount {
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
}

.pending-balance-amount{
  color: #ff2d55;
  font-size: .8em;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: .6em;
  }
}

.withdraw-input-container,
.transfer-input-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
}


.withdraw-all-btn {
  padding: 1rem 1rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: background-color 0.3s ease;
  /* margin-bottom: 10vh; */
  font-size: 1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 1vh;
  }
}

.withdraw-all-btn:hover {
  background-color: #218838;
}

.withdraw-all-btn:disabled {
  background-color: #6c757d;
  cursor: default;
}

.withdraw-input-label,
.transfer-input-label {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #a3ff12;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
}

.withdraw-btn {
  padding: 1rem 1rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 4rem 0.5rem;
  transition: background-color 0.3s ease;
  /* margin-bottom: 10vh; */
  font-size: 1.5rem;
  min-width: 15rem;

  @media (max-width: 768px) {
    margin-bottom: 1vh;
  }
}